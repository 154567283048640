@import "../../../shared/scss/colors";

$base-length: 2rem;
$radius: 50%;
$option-background: $background-main;
$option-hover-background: #cdcdcd;

.reset-undo-tool {
    display: flex;

    .reset-button {
        border-radius: $radius 0 0 $radius;
    }

    .redo-button {
        border-radius: 0 $radius $radius 0;
    }

    .reset-button, .undo-button, .redo-button {
        display: flex;
        justify-content: center;
        align-items: center;

        width: $base-length + 1rem;
        height: $base-length + 1rem;

        background-color: $option-background;

        &:hover {
            background-color: $option-hover-background;
        }
    }
}
