@mixin selected($color){
  box-shadow: 0 0 0 4px $color;
}

@mixin flex-container($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}
