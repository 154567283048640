@import "src/components/shared/scss/colors";

.privacy {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $background-secondary;

    p {
        text-indent: 0;
        color: rgba(0,0,0,0.8);
    }

    .markdown-container {
        max-width: 70%;
    }
}
