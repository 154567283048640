@import "../../shared/scss/breakpoints";

.header-container {
    display: flex;
    justify-content: space-between;
    align-content: center;

    padding:0.5rem;

    h2 {
        font-size: 1.8rem;
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        h2 {
            margin-bottom: 0;
        }
        p {
            margin-top: 0;
        }
    }

    .icon-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        :first-child {
            margin-right: 0.5rem;
        }

        h2, p {
            display: none;
        }
        
        @media screen and (min-width: $tablet) {
            h2, p {
                display: inline;
            }
        }

        .app-icon-img {
            max-width: 60px;
            max-height: 60px;
        }
    }

    .links {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (min-width: $tablet){
            width: inherit;
            justify-content: flex-end;
        }
    }
}
