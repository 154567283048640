$blue: #007AFF;
$red: #FF3B30;
$yellow: #FFCC00;
$orange: #FF9500;
$green: #34C759;
$purple: #AF52DE;
$gray: #8E8E93;
$tan: #F9DCBE;
$brown: #9A6440;
$teal: #31B8BF;
$pink: #FC57CC;
$black: #000;
$white: #FFF;

$background-main: #e9e9e9;
$background-secondary: #fff;
