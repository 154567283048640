.canvas-container {
    .canvas {
        box-shadow: 0 0 1.8vw 1vw rgba(0, 0, 0, 0.06);
        border: 1px rgba(0, 0, 0, 0.125) solid;
        background-color: white;

        width: 100vw;
        height: 80vh;

        touch-action: none;
    }

    .brush-cursor {
        cursor: url( '../cursors/paint-brush-solid.svg' ) 2 16, none;
    }

    .eraser-cursor {
        cursor: url( '../cursors/eraser-solid.svg' ) 2 12, none;
    }
}
