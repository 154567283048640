.app {
  box-sizing: border-box;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-content: center;

  background-color: #f8f8f8;
}

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.48);
}

a:visited {
   color: rgba(0,0,0,0.48);
}

a:hover {
  color: rgba(0,0,0,0.26);
}

