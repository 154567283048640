@import "../../shared/scss/colors";

.markdown-container {
    padding: 2rem 0;
    background-color: $white;

    h1 {
        font-size: 2rem;
        margin-top: 1.6rem;
        margin-bottom: 0.8rem;
        border-bottom: 1px solid #eaecef;
    }
    h2 {
        font-size: 1.6rem;
        margin-top: 1.4rem;
        margin-bottom: 0.8rem;
        border-bottom: 1px solid #eaecef;
    }
    h3 {
        font-size: 1.4rem;
        margin-top: 1.2rem;
    }
    h4 {
        font-weight: bold;
        font-size: 1.2rem;
    }
    ul {
        list-style-type: disc;
        list-style-position: inside;
    }
    li {
        padding-left: 2rem;
    }
    p {
        text-indent: 2rem;
        margin-bottom: 0.8rem;
    }
}
