@import "../../../shared/scss/mixins";
@import "../../../shared/scss/colors";
@import "./variables";

.tool-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: $base-length + 1rem;
    height: $base-length + 1rem;

    .blue, .red, .yellow,
    .orange, .green, .purple,
    .gray, .tan, .brown, .teal,
    .pink, .black, .white {
        width: $base-length;
        height: $base-length;

        border-radius: 100%;

        &:hover {
            width: $base-length + 0.5rem;
            height: $base-length + 0.5rem;
        }
    }

    .blue {
        background-color: $blue;
    }
    .red {
        background-color: $red;
    }
    .yellow {
        background-color: $yellow;
    }
    .orange {
        background-color: $orange;
    }
    .green {
        background-color: $green;
    }
    .purple {
        background-color: $purple;
    }
    .gray {
        background-color: $gray;
    }
    .tan {
        background-color: $tan;
    }
    .brown {
        background-color: $brown;
    }
    .teal {
        background-color: $teal;
    }
    .pink {
        background-color: $pink;
    }
    .black {
        background-color: $black;
    }
    .white {
        background-color: $white;
    }

    .two, .four,
    .eight, .fourteen,
    .twenty, .thirty
    {
        border-radius: 100%;
        border: 1px solid $black;

        &:hover {
            background-color: $black;
        }
    }

    .two {
        width: 2px;
        height: 2px;
    }
    .four {
        width: 4px;
        height: 4px;
    }
    .eight {
        width: 8px;
        height: 8px;
    }
    .fourteen {
        width: 14px;
        height: 14px;
    }
    .twenty {
        width: 20px;
        height: 20px;
    }
    .thirty {
        width: 30px;
        height: 30px;
    }
}


