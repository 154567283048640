@import "src/components/shared/scss/colors";

.change-log {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $background-secondary;

    .markdown-container {
        max-width: 70%;
    }
}
