.tools {
    position: relative;

    .colors {
        position: absolute;
        top: 0.75rem;
        right: 9rem;

        z-index: 200;
    }

    .lines {
        position: absolute;
        top: 0.75rem;
        right: 5rem;

        z-index: 200;
    }

    .brushes {
        position: absolute;
        top: 0.75rem;
        right: 1rem;

        z-index: 200;
    }

    .clear {
        position: absolute;
        top: 0.75rem;
        left: 1rem;

        z-index: 200;
    }
}
