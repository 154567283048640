@import "../../shared/scss/mixins";

.footer-container {
    @include flex-container(column, center, center);

    .copy {
        @include flex-container(row, center, center);

        margin-bottom: 1.2rem;

        &:first-child {
            margin-top: 1.2rem;
        }

        p {
            margin: 0;
        }

        .iconTop {
            color: #666;
        }
        .iconBack {
            color: #e6e6e6;
        }
    }

    .footer-links {
        @include flex-container(row, center, center);

        margin-bottom: 1.2rem;

        a {
            margin: 0 1rem;
        }
    }

    a, a:visited, a:hover {
        color: #1d63ea;
    }
}
