@import "./variables";
@import "../../../shared/scss/colors";

$radius: 50%;
$option-background: $background-main;
$option-hover-background: #cdcdcd;

.tool-dropdown {
    //background-color: #dbdbdb;
}

.selected-container {
    background-color: $option-background;
    border-radius: $radius;

    &:hover {
        background-color: $option-hover-background;
    }
}

.options-container {
    display: none;
    max-height: ($base-length + 1rem) * 7;
    margin-top: 0.5rem;

    &.open {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap-reverse;
    }

    .option {
        background-color: $option-background;

        &:hover {
            background-color: $option-hover-background;
        }

        &.start {
            border-radius: $radius $radius 0 0;
        }
        &.end {
            border-radius: 0 0 $radius $radius;
        }
        &.top-right {
            border-radius: 0 $radius 0 0;
        }
        &.top-left {
            border-radius: $radius 0 0 0;
        }
        &.bottom-right {
            border-radius: 0 0 $radius 0;
        }
        &.bottom-left {
            border-radius: 0 0 0 $radius;
        }
    }
}
